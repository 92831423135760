import React from "react";
import { Layout } from "../../../components/Layout";
import { Hero } from "../../../components/Hero";
import { Gallery } from "../../../components/Gallery";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../../../components/SEO";

const UrbanscapeGallery = ({ data }) => (
  <Layout>
    <SEO
      title="Urbanscape"
      description="Collection of photographs from my travels showing a mixture of the urban architecture and day-to-day adventures"
    />
    <div className="post-container post-full">
      <Hero title="Urbanscape" />
      <main className="post-content-container">
        <Gallery>
          <Img fluid={data.urbanscape4.childImageSharp.fluid}></Img>
          <Img fluid={data.urbanscape2.childImageSharp.fluid}></Img>
          <Img fluid={data.urbanscape3.childImageSharp.fluid}></Img>
        </Gallery>
        <Gallery orientation="wide">
          <Img fluid={data.urbanscape1.childImageSharp.fluid}></Img>
        </Gallery>
        <Gallery orientation="wide">
          <div />
          <Img fluid={data.urbanscape5.childImageSharp.fluid}></Img>
          <Img fluid={data.urbanscape6.childImageSharp.fluid}></Img>
        </Gallery>
      </main>
    </div>
  </Layout>
);

export default UrbanscapeGallery;

export const query = graphql`
  query getNewYorkImagePath {
    urbanscapeImageUrl: file(
      name: { eq: "urbanscape-crop" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
    urbanscape1: file(
      name: { eq: "urbanscape-1" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    urbanscape2: file(
      name: { eq: "urbanscape-2" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    urbanscape3: file(
      name: { eq: "urbanscape-3" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    urbanscape4: file(
      name: { eq: "urbanscape-4" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    urbanscape5: file(
      name: { eq: "urbanscape-5" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    urbanscape6: file(
      name: { eq: "urbanscape-6" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
